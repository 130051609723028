define("discourse/plugins/discourse-encrypt/discourse/connectors/topic-title/decrypt-topic-button", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/render-modifiers/modifiers/did-update", "@ember/service", "discourse/components/d-button", "discourse/components/d-modal", "discourse-common/helpers/i18n", "discourse/plugins/discourse-encrypt/lib/discourse", "discourse/plugins/discourse-encrypt/lib/permanent-topic-decrypter", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _object, _didUpdate, _service, _dButton, _dModal, _i18n, _discourse, _permanentTopicDecrypter, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class DecryptTopicButton extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "modal", [_service.service]))();
    #modal = (() => (dt7948.i(this, "modal"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    get canDecrypt() {
      return this.args.outletArgs.model.encrypted_title && this.currentUser && (0, _discourse.getEncryptionStatus)(this.currentUser) !== _discourse.ENCRYPT_DISABLED && this.siteSettings.allow_decrypting_pms;
    }
    openDecryptModal() {
      this.modal.show(DecryptTopicModal, {
        model: {
          topic_id: this.args.outletArgs.model.id
        }
      });
    }
    static #_4 = (() => dt7948.n(this.prototype, "openDecryptModal", [_object.action]))();
    static #_5 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.canDecrypt}}
          <DButton
            @action={{this.openDecryptModal}}
            @label="encrypt.decrypt_permanently.button"
            @icon="unlock"
            class="decrypt-topic-button"
          />
        {{/if}}
      
    */
    {
      "id": "4j3Rswzy",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"canDecrypt\"]],[[[1,\"      \"],[8,[32,0],[[24,0,\"decrypt-topic-button\"]],[[\"@action\",\"@label\",\"@icon\"],[[30,0,[\"openDecryptModal\"]],\"encrypt.decrypt_permanently.button\",\"unlock\"]],null],[1,\"\\n\"]],[]],null],[1,\"  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-encrypt/discourse/connectors/topic-title/decrypt-topic-button.js",
      "scope": () => [_dButton.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = DecryptTopicButton;
  let DecryptTopicModal = class DecryptTopicModal extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "running", [_tracking.tracked], function () {
      return false;
    }))();
    #running = (() => (dt7948.i(this, "running"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "done", [_tracking.tracked], function () {
      return false;
    }))();
    #done = (() => (dt7948.i(this, "done"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "decrypter", [_tracking.tracked]))();
    #decrypter = (() => (dt7948.i(this, "decrypter"), void 0))();
    async decryptTopic() {
      this.running = true;
      const decrypter1 = new _permanentTopicDecrypter.default(this.args.model.topic_id, this.log);
      this.decrypter = decrypter1;
      try {
        await decrypter1.run();
        this.done = true;
      } catch (e1) {
        // eslint-disable-next-line no-console
        console.error(e1);
      } finally {
        this.running = false;
      }
    }
    static #_4 = (() => dt7948.n(this.prototype, "decryptTopic", [_object.action]))();
    scrollBottom(element1) {
      element1.scrollTop = element1.scrollHeight;
    }
    static #_5 = (() => dt7948.n(this.prototype, "scrollBottom", [_object.action]))();
    refresh() {
      window.location.reload();
    }
    static #_6 = (() => dt7948.n(this.prototype, "refresh", [_object.action]))();
    static #_7 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <DModal
          @closeModal={{if this.done this.refresh @closeModal}}
          @title={{i18n "encrypt.decrypt_permanently.modal_title"}}
          class="decrypt-topic-modal"
        >
          <:body>
            {{#if this.decrypter.logContent}}
              <pre
                style="width: 100%; height: 200px; overflow-y: scroll;"
                {{didUpdate this.scrollBottom this.decrypter.logContent}}
              >
                {{~this.decrypter.logContent~}}
              </pre>
              {{if this.decrypter.success "Refresh page to continue"}}
            {{else}}
              <p>{{i18n "encrypt.decrypt_permanently.modal_body"}}</p>
            {{/if}}
          </:body>
          <:footer>
            {{#if this.done}}
              <DButton
                @label="encrypt.decrypt_permanently.refresh_page"
                class="btn-primary"
                @action={{this.refresh}}
              />
            {{else}}
              <DButton
                @label="encrypt.decrypt_permanently.modal_cancel"
                @action={{@closeModal}}
                disabled={{this.running}}
              />
              <DButton
                @label="encrypt.decrypt_permanently.modal_confirm"
                class="btn-primary"
                @action={{this.decryptTopic}}
                disabled={{this.running}}
                @isLoading={{this.running}}
              />
            {{/if}}
          </:footer>
        </DModal>
      
    */
    {
      "id": "jBdNjVDJ",
      "block": "[[[1,\"\\n    \"],[8,[32,0],[[24,0,\"decrypt-topic-modal\"]],[[\"@closeModal\",\"@title\"],[[52,[30,0,[\"done\"]],[30,0,[\"refresh\"]],[30,1]],[28,[32,1],[\"encrypt.decrypt_permanently.modal_title\"],null]]],[[\"body\",\"footer\"],[[[[1,\"\\n\"],[41,[30,0,[\"decrypter\",\"logContent\"]],[[[1,\"          \"],[11,\"pre\"],[24,5,\"width: 100%; height: 200px; overflow-y: scroll;\"],[4,[32,2],[[30,0,[\"scrollBottom\"]],[30,0,[\"decrypter\",\"logContent\"]]],null],[12],[1,[30,0,[\"decrypter\",\"logContent\"]]],[13],[1,\"\\n          \"],[1,[52,[30,0,[\"decrypter\",\"success\"]],\"Refresh page to continue\"]],[1,\"\\n\"]],[]],[[[1,\"          \"],[10,2],[12],[1,[28,[32,1],[\"encrypt.decrypt_permanently.modal_body\"],null]],[13],[1,\"\\n\"]],[]]],[1,\"      \"]],[]],[[[1,\"\\n\"],[41,[30,0,[\"done\"]],[[[1,\"          \"],[8,[32,3],[[24,0,\"btn-primary\"]],[[\"@label\",\"@action\"],[\"encrypt.decrypt_permanently.refresh_page\",[30,0,[\"refresh\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"          \"],[8,[32,3],[[16,\"disabled\",[30,0,[\"running\"]]]],[[\"@label\",\"@action\"],[\"encrypt.decrypt_permanently.modal_cancel\",[30,1]]],null],[1,\"\\n          \"],[8,[32,3],[[24,0,\"btn-primary\"],[16,\"disabled\",[30,0,[\"running\"]]]],[[\"@label\",\"@action\",\"@isLoading\"],[\"encrypt.decrypt_permanently.modal_confirm\",[30,0,[\"decryptTopic\"]],[30,0,[\"running\"]]]],null],[1,\"\\n\"]],[]]],[1,\"      \"]],[]]]]],[1,\"\\n  \"]],[\"@closeModal\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-encrypt/discourse/connectors/topic-title/decrypt-topic-button.js",
      "scope": () => [_dModal.default, _i18n.default, _didUpdate.default, _dButton.default],
      "isStrictMode": true
    }), this))();
  };
});